<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    @close="reset"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <!-- <el-form-item label="所选节点">
        <el-input v-model="nodeName" placeholder="所选节点" readonly></el-input>
      </el-form-item> -->
      <el-form-item label="分配节点">
        <treeselect
          v-model="dataForm.orgId"
          :options="menuOptions"
          :normalizer="normalizer"
          placeholder="选择上级节点"
          @select="treeselect"
          :multiple="true"
        />

        <!-- :show-count="true" -->
      </el-form-item>
      <el-form-item label="用户名" prop="userName">
        <el-input
          v-model="dataForm.userName"
          placeholder="登录帐号"
          :disabled="dataForm.id != ''"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item
        label="密码"
        prop="password"
        :class="{ 'is-required': !dataForm.id }"
      >
        <el-input
          v-model="dataForm.password"
          type="password"
          placeholder="密码"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item
        label="确认密码"
        prop="comfirmPassword"
        :class="{ 'is-required': !dataForm.id }"
      >
        <el-input
          v-model="dataForm.comfirmPassword"
          type="password"
          placeholder="确认密码"
          show-password
        ></el-input>
      </el-form-item> -->
      <el-form-item label="邮箱" prop="email" v-if="dataForm.id == ''">
        <el-input v-model="dataForm.email" placeholder="邮箱"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="mobile" v-if="dataForm.id == ''">
        <el-input v-model="dataForm.mobile" placeholder="手机号"></el-input>
      </el-form-item>
      <el-form-item label="账号类型" prop="userType" v-if="dataForm.id == ''">
        <el-select
          v-model="dataForm.userType"
          size="small"
          placeholder="账号类型"
          :disabled="orgData.nodeType == 3"
        >
          <el-option label="管理端账号" :value="0"></el-option>
          <el-option label="客户端账号" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="dataForm.id == '' && dataForm.userType == 1">
        <el-checkbox v-model="dataForm.roleType" :true-label="1" :false-label="0">只显示位置监控</el-checkbox>
      </el-form-item>
      <el-form-item
        label="角色"
        size="mini"
        prop="roleIdList"
        v-if="showRole && dataForm.id == ''"
      >
        <el-checkbox-group
          v-if="dataForm.roleIdList != null"
          v-model="dataForm.roleIdList"
        >
          <el-checkbox
            v-for="role in roleList"
            :key="role.roleId"
            :label="role.roleId"
            >{{ role.roleName }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        label="状态"
        size="mini"
        prop="status"
        v-if="dataForm.id == ''"
      >
        <el-radio-group v-model="dataForm.status">
          <el-radio :label="0">禁用</el-radio>
          <el-radio :label="1">正常</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { isEmail, isMobile } from "@/utils/validate";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { treeDataTranslate } from "@/utils";
export default {
  components: { Treeselect },
  props: {
    orgData: {
      //0：平台用户，1：单位用户
      type: Object,
      default: () => {
        return {
          orgId: "",
          nodeType: "",
        };
      },
    },
    nodeName: {
      type: String,
      default: "",
    },
    // menuOptions: {
    //   type: Array,
    //   default: () => [],
    // },
    userType: {
      default: "",
    },
  },
  data() {
    var validatePassword = (rule, value, callback) => {
      if (!this.dataForm.id && !/\S/.test(value)) {
        callback(new Error("密码不能为空"));
      } else {
        callback();
      }
    };
    var validateComfirmPassword = (rule, value, callback) => {
      if (!this.dataForm.id && !/\S/.test(value)) {
        callback(new Error("确认密码不能为空"));
      } else if (this.dataForm.password !== value) {
        callback(new Error("确认密码与密码输入不一致"));
      } else {
        callback();
      }
    };
    var validateEmail = (rule, value, callback) => {
      if (!isEmail(value)) {
        callback(new Error("邮箱格式错误"));
      } else {
        callback();
      }
    };
    var validateMobile = (rule, value, callback) => {
      if (!isMobile(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      roleList: [],
      dataForm: {
        id: 0,
        userName: "",
        password: "",
        comfirmPassword: "",
        email: "",
        mobile: "",
        roleIdList: [],
        status: 1,
        realName: "",
        orgId: [],
        userType: 0,
        roleType: 0
      },
      dataRule: {
        userName: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
        ],
        realName: [
          { required: true, message: "真实姓名不能为空", trigger: "blur" },
        ],
        password: [{ validator: validatePassword, trigger: "blur" }],
        comfirmPassword: [
          { validator: validateComfirmPassword, trigger: "blur" },
        ],
        email: [
          { required: true, message: "邮箱不能为空", trigger: "blur" },
          { validator: validateEmail, trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          { validator: validateMobile, trigger: "blur" },
        ],
        userType: [
          { required: true, message: "请选择用户类型", trigger: "change" },
        ],
      },
      menuOptions: [],
    };
  },
  computed: {
    showRole() {
      let isshow = this.dataForm.userType == 0 && this.orgData.nodeType != 3;
      return isshow;
    },
  },
  methods: {
    init(row) {
      this.menuOptions = [];
      this.dataForm.id = row ? row.userId : 0;
      this.$http({
        url: this.$http.adornUrl("/sys/role/select"),
        method: "get",
        params: this.$http.adornParams(),
      })
        .then(({ data }) => {
          this.roleList = data && data.code === 200 ? data.list : [];
          if (!this.isAuth("sys:orgArch:list1"))
            return this.$message.warning("没有权限!sys:orgArch:list1");
          return this.$http({
            url: this.$http.adornUrl("sys/orgArch/list1"),
            methods: "get",
          });
        })
        .then((res) => {
          this.menuOptions = treeDataTranslate(res.data.data, "id", "parendId");
          this.visible = true;
          this.dataForm.orgId = [];
          this.$nextTick(() => {
            this.$refs["dataForm"].resetFields();
            this.dataForm.userType = this.orgData.nodeType == 3 ? 1 : 0;
          });
        })
        .then(() => {
          if (this.dataForm.id) {
            this.dataForm.userName = row.username;
            this.dataForm.email = row.email;
            this.dataForm.mobile = row.mobile;
            this.dataForm.roleIdList = row.roleIdList;
            this.dataForm.status = row.status;
            this.dataForm.realName = row.realName;
            this.dataForm.userId = row.userId;
            this.dataForm.roleType = row.roleType ?? 0;
            if (!this.isAuth("sys:user:selectUserOrgArchId"))
              return this.$message.warning(
                "没有权限!sys:user:selectUserOrgArchId"
              );
            this.$http({
              url: this.$http.adornUrl(`/sys/user/selectUserOrgArchId`),
              method: "get",
              params: this.$http.adornParams({
                userType: this.userType,
                userId: row.userId,
              }),
            }).then((res) => {
              this.dataForm.orgId = res.data.data.map((item) => item.orgArchId);
            });
          }
        })

        .catch((err) => {
          if (!err || !err.msg) return;
          this.$message.error(err.msg);
        });
    },
    // 表单提交
    dataFormSubmit() {
      let data = null,
        updateurl = "";
      if (this.dataForm.id) {
        data = {
          orgId: this.dataForm.orgId,
          userType: this.userType,
        };
        updateurl = `updateUserNode?userId=${this.dataForm.userId}`;
      } else {
        data = {
          email: this.dataForm.email,
          mobile: this.dataForm.mobile,
          roleIdList: this.showRole ? this.dataForm.roleIdList : null,
          orgId: this.dataForm.orgId,
          status: this.dataForm.status,
          userType: this.dataForm.userType,
          username: this.dataForm.userName,
          roleType: this.dataForm.roleType
        };
      }
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl(
              `sys/user/${!this.dataForm.id ? "save" : updateurl}`
            ),
            method: `${!this.dataForm.id ? "post" : "put"}`,
            // data: {
            //   userId: this.dataForm.id || undefined,
            //   username: this.dataForm.userName,
            //   // password: this.dataForm.password,
            //   email: this.dataForm.email,
            //   mobile: this.dataForm.mobile,
            //   status: this.dataForm.status,
            //   roleIdList: this.showRole ? this.dataForm.roleIdList : null,
            //   orgId: !this.dataForm.id ? this.orgData.id : this.dataForm.orgId,
            //   userType: this.dataForm.userType,
            // },
            data,
          })
            .then(({ data }) => {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.$emit("refreshDataList");
                },
              });
            })
            .catch((err) => {
              this.$message.error(err.msg);
            });
        }
      });
    },
    //获取组织架构列表
    // getOrgList() {
    //   if (!this.isAuth("sys:orgArch:list1"))
    //     return this.$message.warning("没有权限!sys:orgArch:list1");
    //   this.$http({
    //     url: this.$http.adornUrl("sys/orgArch/list1"),
    //     methods: "get",
    //   })
    //     .then((res) => {
    //       this.menuOptions = treeDataTranslate(res.data.data, "id", "parendId");
    //     })
    //     .catch((err) => {
    //       this.$message.error(err.msg);
    //     });
    // },
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.nodeName,
        children: node.children,
      };
    },
    cancel() {
      this.visible = false;
    },
    reset() {
      this.dataForm.orgId = [];
      this.dataForm.userName = "";
      this.dataForm.email = "";
      this.dataForm.mobile = "";
      this.dataForm.roleIdList = [];
      this.dataForm.realName = "";
      this.dataForm.userId = "";
    },
    treeselect(node, instanceId) {},
  },
};
</script>
